import * as React from 'react';
import Layout from '../../components/Layout';
import styled from './index.module.css';
import BlogPostPreviewRoll from './BlogPostPreviewRoll';

const Blog = () => {
  return (
    <Layout>
      <div className={styled.allWrapper}>
        <div className={styled.bodyWrapper}>
          <div className={styled.top}>
            <h2>BLOG</h2>
          </div>
          <div className={styled.body}>
            <BlogPostPreviewRoll />
          </div>
        </div>
        <div className={styled.side}>
          <h1>WOODWORKING WEDNESDAY</h1>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
